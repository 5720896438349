exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("./../../../src/pages/contact/thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-en-about-us-tsx": () => import("./../../../src/pages/en/about-us.tsx" /* webpackChunkName: "component---src-pages-en-about-us-tsx" */),
  "component---src-pages-en-services-services-json-en-slug-tsx": () => import("./../../../src/pages/en/services/{ServicesJson.enSlug}.tsx" /* webpackChunkName: "component---src-pages-en-services-services-json-en-slug-tsx" */),
  "component---src-pages-en-services-solutions-json-en-slug-tsx": () => import("./../../../src/pages/en/services/{SolutionsJson.enSlug}.tsx" /* webpackChunkName: "component---src-pages-en-services-solutions-json-en-slug-tsx" */),
  "component---src-pages-en-work-with-us-tsx": () => import("./../../../src/pages/en/work-with-us.tsx" /* webpackChunkName: "component---src-pages-en-work-with-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-test-tsx": () => import("./../../../src/pages/join/test.tsx" /* webpackChunkName: "component---src-pages-join-test-tsx" */),
  "component---src-pages-join-thanks-tsx": () => import("./../../../src/pages/join/thanks.tsx" /* webpackChunkName: "component---src-pages-join-thanks-tsx" */),
  "component---src-pages-lavora-con-noi-tsx": () => import("./../../../src/pages/lavora-con-noi.tsx" /* webpackChunkName: "component---src-pages-lavora-con-noi-tsx" */),
  "component---src-pages-risorse-tsx": () => import("./../../../src/pages/risorse.tsx" /* webpackChunkName: "component---src-pages-risorse-tsx" */),
  "component---src-pages-servizi-services-json-slug-tsx": () => import("./../../../src/pages/servizi/{ServicesJson.slug}.tsx" /* webpackChunkName: "component---src-pages-servizi-services-json-slug-tsx" */),
  "component---src-pages-servizi-solutions-json-slug-tsx": () => import("./../../../src/pages/servizi/{SolutionsJson.slug}.tsx" /* webpackChunkName: "component---src-pages-servizi-solutions-json-slug-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article-template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-privacy-template-tsx": () => import("./../../../src/templates/privacy-template.tsx" /* webpackChunkName: "component---src-templates-privacy-template-tsx" */),
  "component---src-templates-resource-template-tsx": () => import("./../../../src/templates/resource-template.tsx" /* webpackChunkName: "component---src-templates-resource-template-tsx" */)
}

